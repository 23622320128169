<template>
  <section class="search-form" :class="{'prop': $props.page === 'prop', 'cms': $props.page === 'cms', 'cms-new': $props.page === 'cms-new', 'seo': $props.page === 'seo', 'lodge-form': $props.page === 'lodge', 'expand': expandMob }" v-if="(!isFromSearch && $props.page === 'prop') || $props.page !== 'prop'">
    <form id="vueSearchformDateRange" class="search_fc" @submit.prevent="checkForm" action="/searches" accept-charset="UTF-8" novalidate="novalidate" method="post">
      <div class="search-container">
        <h1 v-if="$props.title">{{ $props.title[0] }}<span>{{ $props.title[1] }}</span>{{ $props.title[2] }}</h1>
        <div class="order-box">
          <div class="region-container">
            <input :id="region" v-autofill="" autocomplete="new-password" name="search[region]" :placeholder="$props.page === 'prop' || $props.page === 'cms' ? 'Search elsewhere...' : 'Destination'" type="text" v-model="filter.region" class="search_region" :class="{'drive': showSearch}" @focus="openSearch" @blur="closeSearch">
            <svg class="ico">
              <use xlink:href="#ico-glass"></use>
            </svg>
            <svg class="search_icon_close ico" v-cloak="" @click="clearRegion" v-show="filter.region.length > 0" >
              <use xlink:href="#ico-close"></use>
            </svg>
            <div class="absolute-container" v-show="showSearch">
              <div id="autofill" class="search_drop search_drop--npad"></div>
              <div class="search_drop search_drop--drive" >
                <ul class="history" v-cloak="" v-show="showSearchHistory">
                  <li class="search_drop_item" v-for="recentSearch in recentSearches" v-if="recentSearches.length" @mousedown="goToSearch(recentSearch.id)">
                    <p v-if="recentSearch.region !==''"> {{ recentSearch.region | removeCountry() }}</p>
                    <p v-if="recentSearch.region === '' && recentSearch.travel_origin_postcode !== ''"> {{ recentSearch.travel_max_time / 60 }} hrs from {{ recentSearch.travel_origin_postcode }}</p>
                    <p v-if="recentSearch.region === '' && recentSearch.travel_origin_postcode === ''">Draw a search</p>
                    <span>{{ recentSearch.check_in_on }} - {{ recentSearch.check_in_on | endDate(recentSearch.minimum_nights) }}</span>
                  </li>
                </ul>
                <div class="search_drop search_drop--h fw-bold" @mousedown="toggleSearch" v-show="!showDrive">
                  Or search by driving time
                </div>
                <div class="search_drop search_drop--h" @mousedown="toggleSearch" v-show="!showDrive">
                  From my postcode
                  <svg>
                    <use xlink:href="#icon-compass"></use>
                  </svg>
                </div>
                <div class="search_drop search_drop--h" @mousedown="findMyLocation" v-show="!showDrive">
                  Locate me
                  <svg>
                    <use xlink:href="#ico-pin"></use>
                  </svg>
                </div>
              </div>
            </div>

            <div class="absolute-container drive" v-show="showDrive" :class="{'shadow': showDriveExpanded, 'error': errors.postcode}">
              <div class="search_drop search_drop--drive" v-show="showDrive" >
                <select name="search[travel_max_time]" @change="focusEl('#postcode')" v-model="filter.travel_max_time" data-required="required" data-valid="invalid" class="travelTime form_valid driveSearch" data-active="inactive">
                  <option selected="selected" value="0" disabled="true">Driving time*</option>
                  <option v-for="interval in timeIntervals" :value="interval.value">
                    {{interval.text}}
                  </option>
                </select>
                <span>from</span>
                <input id="postcode" class="search_post form_valid" name="search[travel_origin_postcode]" @blur="closeDrive" @focus="openDrive" data-required="required" data-valid="invalid" data-active="inactive" placeholder="Postcode" v-model="filter.travel_origin_postcode" @change="geoCode" type="text" />
                <svg v-show="!showDriveExpanded" >
                  <use xlink:href="#ico-car"></use>
                </svg>
                <div class="search_icon" v-cloak="" v-show="showDriveExpanded" @click.stop="focusAndClose('#date')">&#x276F;</div>
              </div>
              <div v-show="showDriveExpanded" class="search_drop search_drop--h" @mousedown="findDriveLocation">
                Locate me
                <svg class="ico ico--25">
                  <use xlink:href="#ico-pin"></use>
                </svg>
              </div>
              <div class="search_drop search_drop--h" v-show="showDriveExpanded" @mousedown="toggleSearch">
                Or search by region
                <svg class="ico ico--25">
                  <use xlink:href="#ico-map"></use>
                </svg>
              </div>
            </div>
            <p class="error-message" v-if="errors.postcode">We can't find your location. Please try again</p>
          </div>
          <div class="date-box box" :class="{'error': errors.date, 'opened': showPicker}">
            <div class="date-container" @click="openLitepicker">
              <div class="date-box-block">
                <svg class="ico ico--20">
                  <use xlink:href="#icon-calendar"/>
                </svg>
                <div>
                  <b>Check-in</b>
                  <p v-if="checkInFormatted !== ''">{{ checkInFormatted }}</p>
                  <p v-if="checkInFormatted === ''" class="no_dates">Add dates</p>
                </div>
                <span class="form_flex" v-if="filter.flex === '3' || filter.flex === '7'">&#177; {{filter.flex}}</span>
              </div>
              <div class="date-box-block">
                <svg class="ico ico--20">
                  <use xlink:href="#icon-calendar"/>
                </svg>
                <div>
                  <b>Check-out</b>
                  <p v-if="checkOutFormatted !== ''">{{ checkOutFormatted }}</p>
                  <p v-if="checkOutFormatted === ''" class="no_dates">Add dates</p>
                </div>
                <span class="form_flex" v-if="filter.flex === '3' || filter.flex === '7'">&#177; {{filter.flex}}</span>
              </div>
            </div>
            <input type="hidden" name="search[check_in_on]" v-model="filter.check_in_on"/>
            <input type="hidden" name="search[minimum_nights]" v-model="filter.minimum_nights"/>
            <input class="btn" @click="openLitepicker" v-picker="" readonly type="text" autocomplete="off" :id="$props.cal+'-date'"/>
            <div :id="$props.cal+'-litepicker-container'" class="litepicker-container" v-show='showPicker'>
              <div class="buttons-container">
                <div class="flex-buttons">
                  <button value="3" type="button" class="flex-button" @click="setFlexPicker($event)" :class="{ selected: filter.flex === '3' }">
                    &#177; 3 days
                  </button>
                  <button value="7" type="button" class="flex-button" @click="setFlexPicker($event)" :class="{ selected: filter.flex === '7' }">
                    &#177; 7 days
                  </button>
                </div>
              </div>
            </div>
            <p class="error-message" v-if="errors.date">
              Please add dates
            </p>
          </div>
          <Select
              :options="['1+', '2+', '3+', '4+', '5+', '6+','7+', '8+', '9+', '10+', '11+','12+','13+', '14+', '15+', '16+', '17+', '18+', '19+', '20+']"
              :search="'search[sleeps]'"
              :name="'Guests'"
              :placeholder="'Guests'"
              :data.sync="filter.sleeps"
          />
          <input type="hidden" name="search[flex]" v-model="filter.flex"/>
          <input type="hidden" v-for="param in $props.params" :name="'search[' + param[0] + ']'" :value="param[1]"  />
          <button class="submit-search btn btn--org btn--lrg btn--wide">Search</button>
        </div>
      </div>
    </form>

    <div class="search_loader" :class="{ 'visible': loader && showDrive && !errors.postcode }">
      <div class="center">
        <svg class="ico ico--70" @click="checkForm">
          <use xlink:href="#ico-car"></use>
        </svg>
        <p class="search_loadcap">
          Now driving your route
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </p>
      </div>
    </div>
  </section>
</template>

<script>

import Select from 'shared/guestSelect.vue'
import memCTA from 'shared/member_cta.vue'

export default {
  data: function () {
    return {
      url: "/searches/custom?",
      currentUrl: window.location.href,
      isFromSearch: document.referrer.indexOf('searches') > -1 || document.referrer.indexOf('top-deals') > -1 || document.referrer.indexOf('properties') > -1,
      timeIntervals: [
        {'value':30, 'text': '30 Mins'},
        {'value':60, 'text': '1 Hour'},
        {'value':90, 'text': '1.5 Hours'},
        {'value':120, 'text': '2 Hours'},
        {'value':150, 'text': '2.5 Hours'},
        {'value':180, 'text': '3 Hours'},
        {'value':210, 'text': '3.5 Hours'},
        {'value':240, 'text': '4 Hours'},
        {'value':270, 'text': '4.5 Hours'},
        {'value':300, 'text': '5 Hours'}
      ],
      recentSearches: [],
      showSearchHistory: true,
      showSearch: false,
      showDrive: false,
      showDriveExpanded: false,
      showClearRegion: false,
      loader: false,
      latLng: {},
      expandMob: false,
      showPicker: false,
      initDate:null,
      initNights:null,
      initFlex:null,
      filter: {
        region: this.$props.loc || "",
        travel_origin_postcode: "",
        travel_max_time: 120,
        pets: "",
        check_in_on: "",
        minimum_nights: "",
        sleeps: "",
        flex: 1,
      },
      errors: {
        postcode: false,
        nights: false,
        date: false,
        time: false
      },
      checkInFormatted: "",
      checkOutFormatted: "",
    }
  },
  props: {
    title: Array,
    region: String,
    params: Array,
    page: Object,
    loc: String,
    wknd: String,
    page: String,
    cal: String
  },
  components: {
    Select, memCTA
  },
  filters: {
    endDate(date, nights) {
      let result = new Date(date);
      return st.getUserDate(result.setDate(result.getDate() + parseInt(nights)));
    },
    removeCountry(text) {
      return text.replace(/,.*$/g, '');
    }
  },
  methods: {
    postRequest() {
      return {
        method: "POST",
        credentials: "include",
      };
    },
    hasNumber (value) {
      var filter = /\d/;
      return filter.test(value);
    },
    clearRegion(){
      this.filter.region = "";
      this.showClearRegion = false;
      this.focusEl("#region");
    },
    focusEl(el) {
      let elem = this.$el.querySelector(el);
      if (elem) {
        setTimeout(() => {
          this.$nextTick(() => elem.focus());
        }, 100);
      }
    },
    focusAndClose(el) {
      this.showDriveExpanded = false;
      this.focusEl(el);
    },
    getGeocode (app) {
      st.ajax({
        url: "https://api.mapbox.com/geocoding/v5/mapbox.places/" + encodeURIComponent(app.filter.travel_origin_postcode) + ".json?country=gb&access_token=pk.eyJ1Ijoic25hcHRyaXBrcmlzIiwiYSI6ImNqa2RvejJucDB5bm4zcnM1ZDlyOHVqZnQifQ.VZTOARdDaid-d4yf2Q182g",
        type: "GET",
        dataType: "json"
      }, function(response) {
        if (response.features[0]) {
          app.latLng = { lng: response.features[0].center[0], lat: response.features[0].center[1] };
          app.errors.postcode = false;
        } else {
          app.errors.postcode = true;
        }
      });
    },
    getGeoLocation (app) {
      let scope = this;
      if (navigator.geolocation) {
        app.errors.postcode = false;
        app.filter.travel_origin_postcode = "Finding...";
        navigator.geolocation.getCurrentPosition(function(position) {
          scope.getPostcode({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            data: app
          });
        }, function(error) {
          if (error.code === error.PERMISSION_DENIED) {
            app.toggleSearch();
            app.errors.postcode = true;
          }
        });
      }
    },
    getPostcode (options) {
      let app = options.data;
      st.ajax({
        url: "https://api.mapbox.com/geocoding/v5/mapbox.places/" + encodeURIComponent(options.lng + "," + options.lat) + ".json?access_token=pk.eyJ1Ijoic25hcHRyaXBrcmlzIiwiYSI6ImNqa2RvejJucDB5bm4zcnM1ZDlyOHVqZnQifQ.VZTOARdDaid-d4yf2Q182g",
        type: "GET",
        dataType: "json"
      }, function(response) {
        app.filter.travel_origin_postcode = response.features[0].context[0].text;
        app.latLng = { lng: options.lng, lat: options.lat };
      });
    },
    geoCode () {
      this.getGeocode(this);
    },
    findMyLocation () {
      this.toggleSearch();
      this.getGeoLocation(this);
    },
    findDriveLocation () {
      this.getGeoLocation(this);
    },
    toggleSearch () {
      let app = this;
      app.showDrive = !app.showDrive;
      app.showSearch = !app.showSearch;
      app.showDriveExpanded = !app.showDriveExpanded;
      if (app.showDrive) {
        app.filter.region = "";
        this.focusEl("#postcode");
      } else {
        app.filter.travel_max_time = 120;
        app.filter.travel_origin_postcode = "";
        app.errors.postcode = false;
        this.focusEl("#region");
      }
    },
    openDrive () {
      let app = this;
      app.showDriveExpanded = true;
    },
    closeDrive () {
      let app = this;
      app.showDriveExpanded = false;
    },
    openSearch (e) {
      let app = this;
      app.showSearch = true;
      app.expandMob = true;
    },
    closeSearch() {
      let app = this;
      app.showSearch = false;
    },
    goToSearch (id) {
      window.location.href = id ? "/searches/" + id : "/top-deals";
    },
    //history
    getHistory(item, limit) {
      let history = localStorage.getItem(item),
          newData = [];
      if (st.checkLocal() && history !== null && history.length > 0) {
        newData = JSON.parse(history).slice(0, limit);
        return newData.reverse();
      } else {
        return [];
      }
    },
    cleanSearchHistory () {
      let app = this;
      let today = new Date().getTime(),
          allRecords = app.getHistory("search_history", 10),
          records = [];
      for (let i = 0; i < allRecords.length; i++) {
        let record = allRecords[i];
        if ((record.isochrone_uuid !== "" || record.region !== "" ) && new Date(record.check_in_on).getTime() > today) {
          if (records.length < 3) {
            records.push(record);
          }
        }
      }
      if (records.length === 1 || records.length === 2) {
        app.pushTopDealsCard(records);
      }
      return records;
    },
    pushTopDealsCard(records) {
      let app = this;
      records.push({
        region: "Latest UK deals",
        check_in_on: app.$props.wknd,
        nights: 3,
        minimum_nights: 3,
        isochrone_uuid: ""
      });
    },
    //validation & submission
    checkForm () {
      let scope = this;
      let form = scope.$el.children[0];
      scope.clearAll();
      scope.validateForm(scope, form);
    },
    clearAll () {
      this.errors.nights = false;
      this.errors.date = false;
      this.errors.time = false;
    },
    isRefOnly (value) {
      return this.hasNumber(value);
    },
    validateForm (scope, form) {
      let app = this;
      if (scope.filter.minimum_nights === 0 || scope.filter.minimum_nights === "") {
        scope.errors.nights = true;
      }
      if (scope.filter.check_in_on === "") {
        scope.errors.date = true;
      }
      if (scope.showDrive) {
        app.validateDrive(scope);
        return;
      }
      if (app.isRefOnly(scope.filter.region) || !(scope.errors.date || scope.errors.nights || scope.errors.postcode)) {
        form.submit();
      }
    },
    validateDrive (scope) {
      let app = this;
      this.getGeocode(scope);
      if (scope.filter.travel_origin_postcode === "") {
        scope.errors.postcode = true;
        return;
      }
      if (scope.filter.travel_max_time === "") {
        scope.errors.time = true;
        return;
      }
      if (scope.filter.check_in_on === "") {
        scope.errors.date = true;
        return;
      }
      app.loader = true;
      app.createUrl();
    },
    createUrl() {
      var app = this;
      var search = { search: app.filter };
      this.calcIso({
        time: parseInt(app.filter.travel_max_time),
        position: app.latLng,
        callback: function(response) {
          callback(response, app);
        }
      });
    },
    //isochrone
    calcIso (vars) {
      let scope = this;
      let url = "/api/v2/isochrones?lat=" + vars.position.lat + "&lng=" + vars.position.lng + "&travelTimeInSecs=" + vars.time * 60;

      fetch(url, scope.postRequest())
          .then(function(resp) {
            if (resp.status === 201){
              return resp.json();
            } else {
              scope.errors.postcode = true;
              return;
            }
          })
          .then(function(data) {
            scope.isoCallBack(data, vars.callback, vars.position);
          })
          .catch(error => console.log(error));
    },
    isoCallBack (response, uuidCallback, position) {
      let scope = this;
      let uuid = response._meta.uuid;
      let guests = scope.filter.sleeps ? scope.filter.sleeps : 1;
      scope.uuid = uuid;
      window.location.href = scope.url + "search[isochrone_uuid]=" + uuid + '&search[travel_max_time]=' + scope.filter.travel_max_time + '&search[travel_origin_postcode]=' + scope.filter.travel_origin_postcode + '&search[check_in_on]=' + scope.filter.check_in_on + '&search[minimum_nights]=' + scope.filter.minimum_nights + '&search[sleeps]=' + guests;
    },
    setFlexPicker: function(ev) {
      const value = ev.target.value;

      if (value === this.filter.flex) {
        this.filter.flex = 1;
      } else {
        this.filter.flex = value;
      }
    },
    openLitepicker: function() {
        let app = this;
        app.initDate = app.filter.check_in_on;
        app.initNights = app.filter.minimum_nights;
        app.initFlex = app.filter.flex
        app.showPicker= true;
    },
    closeLitepicker: function() {
      let app = this;
      app.showPicker = false;
    },
  },
  directives: {
    'autofill': {
      inserted: (el, binding, vnode) => {
        let af = document.querySelector("#autofill");
        let app = vnode.context,
            url = window.location.href;
        el.autofill = new autoComplete({
          selector: el,
          minChars: 1,
          cache: false,
          source: function (term, suggest) {
            if (term.length > 2) {
              st.ajax({
                url: "/searches/autocomplete?query=" + encodeURIComponent(term.toLowerCase()),
                type: "GET",
                csrf: st.getCSRFToken(),
                dataType: "json"
              }, function (response) {
                suggest(response);
              });
              app.showSearchHistory = false;
            } else {
              app.showSearchHistory = true;
            }
          },
          renderItem: function(item, search) {
            search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
            var re = new RegExp("(" + search.split(' ').join('|') + ")", "gi");
            if(item.includes(",")){
              return '<div class="autocomplete-suggestion" data-val="' + item + '">' + item.replace(re, "<b>$1</b>").replace(",", "+").split("+")[0] + '<br /><span class="sub-region">' + item.replace(re, "<b>$1</b>").replace(",", "+").split("+")[1]  + '</span></div>';
            } else {
              return '<div class="autocomplete-suggestion" data-val="' + item + '">' + item.replace(re, "<b>$1</b>") + '</div>';
            }
          },
          onSelect: function (e, term, item) {
            e.preventDefault();
            app.filter.region = term;
            app.showSearch = false;
          }
        });
        if (af) {
          af.appendChild(document.querySelector(".autocomplete-suggestions"));
        }
        el.autocomplete = url.indexOf("snaptrip") > -1 && url.indexOf("properties") === -1 ? "new-password" : "off";
      },
      unbind: (el) => {
        el.autofill.destroy();
      }
    },
    'picker': {
      inserted: function(el, binding, vnode) {
        let app = vnode.context;

        el.picker = new Litepicker({
          element: document.getElementById(app.$props.cal+'-date'),
          parentEl: document.getElementById(app.$props.cal+'-litepicker-container'),
          singleMode: false,
          autoApply: false,
          resetButton:true,
          allowRepick: true,
          minDate: new Date() -1,
          numberOfColumns: 2,
          numberOfMonths: 2,
          switchingMonths: 1,
          minDays: 2,
          maxDays: 29,
          format: "D MMM YYYY",
          tooltipText: {
            one: 'night',
            other: 'nights'
          },
          inlineMode:true,
          buttonText: {
            cancel:'Close'
          },
          tooltipNumber: (totalDays) => {
            return totalDays - 1;
          },
          setup: (picker) => {
            picker.on('button:cancel', () => {
                 if (app.initDate) {
                     let checkOut = new Date(app.initDate)
                     let checkOutDate = new Date(checkOut.getTime())
                     checkOutDate.setDate(checkOutDate.getDate() + parseInt(app.initNights))
                     picker.setDateRange(checkOut, checkOutDate)
                 } else {
                     picker.clearSelection()
                 }

                 app.filter.flex = app.initFlex
                 app.closeLitepicker();
            });
            picker.on('clear:selection', () => {
              app.filter.flex = 1
              app.checkInFormatted = "";
              app.checkOutFormatted = "";
            });
            picker.on('preselect', (date1, date2) => {
              if (date2) {
                picker.setDateRange(date1, date2)
                app.checkInFormatted = date1.dateInstance.toLocaleDateString('en-US', {
                  weekday: 'short',
                  day: '2-digit',
                  month: 'short'
                });
                app.checkOutFormatted = date2.dateInstance.toLocaleDateString('en-US', {
                  weekday: 'short',
                  day: '2-digit',
                  month: 'short'
                });
                app.checkInFormatted = app.checkInFormatted.replace(/,/g, ' ')
                app.checkOutFormatted = app.checkOutFormatted.replace(/,/g, ' ')
              }
            });
            picker.on('button:apply', (date1, date2) => {
                if (date2) {
                    const timeDiff = Math.abs(date2.dateInstance.getTime() - date1.dateInstance.getTime());
                    app.filter.check_in_on = date1.dateInstance.toString().split('00:00:00')[0];
                    app.filter.minimum_nights = Math.ceil(timeDiff / (1000 * 3600 * 24));
                } else {
                    app.filter.check_in_on = "";
                    app.filter.minimum_nights = "";
                }

                app.closeLitepicker();
            });
            picker.on('hide', () => {
              app.showPicker = false;
              if (app._props.page === 'cms-new') {
                document.body.classList.remove('show-cal');
              }
            });
            picker.on('show', () => {
              if (app._props.page === 'cms-new') {
                document.body.classList.add('show-cal');
              }
            });
           }
        });
      },
      unbind: function unbind(el) {
        el.picker.destroy();
      }
    }
  },
  mounted: function () {
    let app = this;
    app.recentSearches = app.cleanSearchHistory();

    if (app._props.page === 'seo') {
      document.addEventListener('scroll', function(e) {
        if (window.pageYOffset >= 800) {
          app.$el.closest('.following-form').classList.add('show')
        } else {
          app.$el.closest('.following-form').classList.remove('show')
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
@import 'app/assets/stylesheets/_vars.scss';

.btn--lrg {
  height: 3rem;

  @media #{$desktop} {
    max-width: 10rem;
  }
}

.search-form {
  height: 100%;
  display: flex;
  align-items: end;

  .search_fc {
    width: 100%;
  }

  &.prop, &.cms, &.seo, &.lodge-form {
    position: relative;
    top: 0;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    height: auto;
    overflow: auto;
    background-position-y: bottom;

    .search_loader {
      display: none !important;
    }

    &.expand {
      overflow: visible;
    }

    .history li span{
      margin: 0;
    }

    .search_loader {
      height: 14rem;
    }

    .search_drop--drive span {
      margin: 0;
    }

    .search-container {
      background-image: none;
      background-color: unset;
      padding: 0;
      max-height: 3rem;
      transition: max-height 1s ease;

      .search_icon {
        top: 1rem;
      }

      h1 {
        display: none;
      }

      .order-box {
        padding: 0;

        .box:last-of-type {
          display: none;
        }
      }

      .btn {
        margin: 0;
        width: 100%;
        font-weight: 600;
      }

      .search_region {
        background-color: $col_white;
        padding: 0 5rem 0 2.5rem;
        border: .063rem solid #d8d8d8;

        &.drive {
          z-index: 4;
          margin-top: 0;
        }

      }
    }

    &.expand .search-container  {
      max-height: 15rem;
    }

    .absolute-container {
      background: transparent;

      .search_drop {
        background: $col_white;
      }

      &.drive {
        margin: 0;
        z-index: 4;

        .search_drop {
          &--drive {
            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active{
              -webkit-box-shadow: 0 0 0 30px $col_white inset !important;
            }
          }
        }

        select {
          max-width: 5rem;
        }
      }
    }

    .search_drop {
      &--drive {
        padding: 0;

        .history {
          background-color: $col_white;
        }
      }
      &--h {
        z-index: 4;
      }
    }

    .box {
      select {
        border-radius: 2px;
        box-shadow: 0 0.125rem 6px rgba(0, 0, 0, 0.1);
      }
    }
  }

  &.cms {
    .region-container, .date-box {
      .error-message {
        display: none;
      }
    }
  }

  &.prop {
    padding: .5rem 0;

    @media #{$desktop} {
      padding: 0;
    }

    .search_fc {
      padding-bottom: 0;
    }
  }

  &.seo .search-container .search_icon {
    top: 1.6rem;
  }

  &.lodge-form .search-container .search_icon {
    top: 1rem;
  }

  .form_flex {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: fit-content;
    right: 0.5rem;
    font-size: 0.8rem;
    line-height: 1.25rem;
  }

  h1 {
    z-index: 2;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.25rem;
    padding: 0 1.25rem;
    position: relative;
    text-align: center;
    line-height: 1.5rem;

    span {
      font-weight: 700;
    }
  }

  .ico {
    z-index: 4;
    left: 1rem;
    position: absolute;
    top: 1rem;
    stroke: $col_black;
    stroke-width: 2rem;
    cursor: pointer;
    width: 1rem;
    height: 1rem;

    &.search_icon_close {
      left: unset;
      right: 1.25rem;
      stroke: none;
    }
  }

  .search_icon {
    width: 1.4rem;
    height: 1.4rem;
    right: 1.25rem;
    border-radius: 100%;
    border: none;
    background: $col_primary;
    color: #FFF;
    text-align: center;
    line-height: 1.4rem;
    position: absolute;
    top: 1rem;
    z-index: 2;
    cursor: pointer;
  }

  .search-container {
    margin: 0 auto;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    background-color: #FFF;
    flex-direction: column;
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 1.25rem 1.25rem 1rem 1.25rem;

    h1 {
      order: 1;
    }

    .ctr {
      order: 2;
      display: flex;
      width: 100%;
    }

    .order-box {
      order: 3;
      display: flex;
      flex-direction: column;
      grid-row-gap: 0.625rem;
      padding: 1.25rem 0 0 0;
      grid-column-gap: 0.625rem;
    }
  }

  .search_loader {
    display: none;
    border-radius: .19rem;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 24rem;
    position: absolute;
    background: #FFF;
    z-index: 100;
    padding: 1.25rem;
    box-sizing: border-box;

    &.visible {
      display: block;

      .search_loadcap span {
        animation: blink 1.4s infinite;
        animation-fill-mode: both;
        font-size: 1.5rem;

        &:nth-child(2) {
          animation-delay: .2s;
        }

        &:nth-child(3) {
          animation-delay: .4s;
        }
      }
    }

    .center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .ico {
        width: 4.375rem;
        height: 4.375rem;
        cursor: pointer;
        stroke: #000;
        stroke-width: 0;
        position: relative;
        right:0;
      }

      .search_loadcap {
        margin: 1rem 0 0 0;
      }
    }
  }

  .search-row {
    height: 3rem;
    position: relative;
    width: calc(50% - .625rem);
    float: left;
    box-sizing: border-box;
  }

  .search_region {
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    line-height: 3rem;
    font-size: .875rem;
    width: 100%;
    padding: 0 2rem 0 2.5rem;
    height: 3rem;
    outline: 0;
    cursor: text;
    float: left;
    border-radius: .188rem;
    background: #F5F4F3;
    border: 1px solid #d8d8d8;
    color: $col_black;

    &::placeholder {
      color: $col_black;
    }

    &.drive {
      background-color: #F5F4F3;
      border-bottom: .063rem solid transparent;
    }

    &::-ms-clear {
      display: none;
    }
  }

  svg.search_t {
    height: 1rem;
    width: 1rem;
  }

  .form_clabel {
    float: left;
    width: 100%;
  }

  .search_drop {
    box-sizing: border-box;
    max-width: 100%;
    border-radius: .188rem;
    top: 0;
    left: 0;
    z-index: 7;
    max-height: 1000px;
    overflow: initial;
    background-color: #F5F4F3;

    &:hover {
      color: $col_primary;
    }

    &--h {
      position: relative;
      height: 100%;
      line-height: 3rem;
      font-size: .875rem;
      width: 100%;
      padding: 0 2rem 0 1rem;
      cursor: pointer;

      svg {
        left: unset;
      }
    }

    &.fw-bold {
      font-weight: 700;
    }

    svg {
      margin-top: -.813rem;
      right: 1.25rem;
      position: absolute;
      top: 50%;
      stroke-width: initial;
      fill: $col_primary;
      cursor: pointer;
      width: 1.563rem;
      height: 1.563rem;
    }
  }

  .region-container {
    z-index: 11;
    width: 100%;
    position: relative;

    .error-message {
      height: 1rem;
      color: $col_red;
      padding-top: 3.5rem;
      font-size: 0.875rem;
    }
  }

  .date-box {
    width: 100%;
    height: 3rem;
    position: relative;
    border-radius: .188rem;
    box-sizing: border-box;
    border: 1px solid #d8d8d8;
    background-color: #f5f4f3;

    &.error {
      border-color: $col_red;
      margin-bottom: 1rem;
    }

    .error-message {
      height: 1rem;
      color: $col_red;
      font-size: 0.875rem;
      padding-top: 0.5rem;
    }

    .date-container {
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: grid;
      position: absolute;
      grid-template-columns: 1fr 1fr;

      .date-box-block {
        display: flex;
        padding: 0 1rem;
        position: relative;
        align-items: center;
        flex-direction: row;

        &:first-of-type {
          &:after {
            right: 0;
            width: 1px;
            content: "";
            height: 100%;
            position: absolute;
            background: #d8d8d8;
          }
        }

        svg {
          top: unset;
          left: unset;
          width: 1rem;
          height: 1.063rem;
          position: relative;
          margin-right: 0.625rem;
        }

        div {
          b {
            font-size: 0.75rem;
          }

          p {
            line-height: 1.4;
            white-space: nowrap;
            font-size: 0.875rem;
          }
        }
      }
    }

    input {
      opacity: 0;
    }
  }

  .absolute-container {
    position: absolute;
    box-sizing:border-box;
    top: 0;
    width: 100%;
    padding: 2.875rem 0 0 0;
    border: .063rem solid #d8d8d8;
    border-radius: .188rem;

    &.drive {
      z-index: 4;
      padding: 0;
      outline: 0;
      float: left;
      font-size: .875rem;
      background: #F5F4F3;
      line-height: 3rem;
      border: .063rem solid #d8d8d8;

      &.error{
        border: .063rem solid $col_red;
        color: $col_red;

        .search_drop {
          height: 2.875rem;
        }

        input,
        input::placeholder {
          color: $col_red;
        }
      }

      .search_drop {
        height: 3rem;
        padding: 0 2rem 0 1rem;

        &--drive {
          display: flex;
          align-items: center;
          flex-direction: row;

          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px #f5f4f3 inset !important;
          }
        }
      }

      select {
        height: 100%;
        font-size: .875rem;
        outline: 0;
        width: auto;
        max-width: 5.625rem;
        font-weight: 700;
        background: transparent;
      }

      span {
        margin: 0 .5rem;
        font-size: .875rem;
        outline: 0;
        width: auto;
      }

      input {
        height: 100%;
        line-height: 3rem;
        font-size: .875rem;
        outline: 0;
        width: 80%;
        font-weight: 700;
        background: transparent;

        &::placeholder {
          font-weight: 400;
        }
      }
    }
  }

  .history {
    box-sizing: border-box;
    list-style: none;
    padding: 0;
    line-height: 3rem;
    font-size: .875rem;
    width: 100%;
    cursor: pointer;
    background: #f5f4f3;

    &:after {
      content: '';
      position: absolute;
      width: 90%;
      left: 50%;
      transform: translateX(-50%);
      background: #B4B4B4;
      height: 1px;
      display: block;
      z-index: 8;
    }

    li {
      font-weight: 700;
      text-transform: capitalize;
      width: 100%;
      box-sizing: border-box;
      padding: 1rem;
      font-size: .8rem;
      cursor: pointer;

      &:hover {
        p, span {
          color: $col_primary;
        }
      }

      p {
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;
        width: 40%;
      }

      span {
        float: right;
        font-weight: 400;
        width: 60%;
        text-transform: uppercase;
        text-align: right;
      }
    }
  }

  .search_pnts {
    padding: 1.25rem 0 0;
    width: 100%;
    position: relative;
    flex-direction: row;
    flex: 1 1 auto;
    display: none;
  }

  .search_txt {
    text-align: center;
    font-weight: 400;
    width: calc(100% / 3);
    font-size: .875rem;

    .search_t {
      width: .875rem;
      top: .125rem;
      height: .875rem;
      position: relative;
      fill: $col_white;
    }
  }

  .foot {
    padding: 1.25rem 0;

    & * {
      color: $col_white;
      text-shadow: 0 .125rem .125rem #221F20;
    }
  }
}

@keyframes blink {
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}

@media #{$xs-phone} {
  .search-form .history li span {
    font-size: .7rem;
  }
}

@media #{$desktop} {
  .search-form {
    padding: 0;

    .search_fc {
      padding-bottom: 4rem;
    }

    &.lodge-form {
      margin-bottom: 2rem;
    }

    &.seo {
      width: 100%;
    }

    &.cms {
      padding-bottom: 0;
      width: calc(100% - 2.5rem);
    }

    &.lodge-form {
      max-width: 90rem;
    }

    &.prop, &.cms, &.seo, &.lodge-form {
      .absolute-container {
        &.drive {
          margin: 0;

          .search_drop {
            height: 3.025rem;

            &--h {
              line-height: 3.025rem;
            }
          }
        }
      }

      .search_loader {
        height: auto;

        .center {
          display: flex;
          justify-content: center;
          align-items: center;

          .ico {
            top:0;
            margin-right: 1rem;
          }

          .search_loadcap {
            margin: 0;
          }
        }
      }

      .search_drop{
        &--h {
          padding: 1rem 2rem 1rem 1rem;
          line-height: 1.5rem;
        }

        svg {
          right: 1.25rem;
        }
      }

      .search-container {
        max-height: none;
        width: 100%;
        max-width: 75rem;
        box-shadow: none;
        padding: .75rem 0;

        .btn {
          width: calc(50% - .625rem);
        }

        .region-container {
          z-index: 4;
          min-width: 18.1rem;

          input {
            margin: 0;
            background: $col_white;
            padding: 0 2rem 0 2.5rem;

            &.search_post {
              padding: 0 1rem 0 0.5rem;
            }
          }

          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px $col_white inset !important;
          }
        }

        .order-box {
          display: flex;

          .box:last-of-type {
            display: block;
          }
        }

        .date-box {
          .box {
            .btn {
              background-color: $col_white;
            }
          }
        }
      }
    }

    &.seo .search-container {
      width: 100%;
    }

    h1 {
      margin-bottom: 1.25rem;
      font-size: 1.5rem;
      color: #000;
      text-align: left;
      padding: 0;
      margin-top: 0;
      text-shadow: none;

      span {
        color: #000;
      }
    }

    .search_loader {
      height: 14rem;
      bottom: 2rem;
    }

    .search-container {
      padding: 1.25rem;
      background-color: #FFF;
      position: relative;
      box-sizing: border-box;
      border-radius: .5rem;
      box-shadow: 0 0 1.25rem rgba(0,0,0,.3);
      display: block;

      .order-box {
        flex-direction: row;
      }

      .date-box {
        .btn {
          width: 100%;
        }
      }

      .order-box {
        padding: 0;
      }
    }

    .date-box {
      .btn {
        width: 100%;
        margin: 0;
      }

      &.error {
        ::placeholder {
          color: $col_red;
        }

        :-ms-input-placeholder {
          color: $col_red;
        }

        ::-ms-input-placeholder {
          color: $col_red;
        }
      }
    }

    .foot {
      & * {
        color: $col_black;
        text-shadow: none;
      }

      .search_t {
        fill: black;
      }
    }

    .search_pnts {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
    }
  }
}

</style>

<style lang="scss">
@import 'app/assets/stylesheets/_vars.scss';

.search-form.prop, .search-form.cms, .search-form.seo, .search-form.lodge-form {
  .box {
    &.error input.btn{
      border: .063rem solid #e84118;
    }

    input.btn {
      padding: 0 1rem;
      border: .063rem solid #d8d8d8;
    }

    .select select {
      background: $col_white;
    }
  }

  .date-box {
    box-sizing: border-box;
    border-radius: .188rem;
    border: 1px solid #d8d8d8;

    .date-container {
      border-radius: 2px;
      background-color: $col_white;
    }
  }

  .btn--lrg {
    max-width: unset;
  }
}

.search-form {
  .box input,
  .box .select {
    min-width: 8rem;
    border: 1px solid #d8d8d8;
  }

  .box {
    display: inline-block;
    float:left;
    position: relative;

    &.date-box {
      input {
        margin: 0;
      }
    }

    .select {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg enable-background='new 0 0 500 500' version='1.1' viewBox='0 0 500 500' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate%28-186 -303%29'%3E%3Cg transform='translate%2820 281%29'%3E%3Cg transform='translate%28160 15%29'%3E%3Cpath d='m29.729 155.09c0-4.702 1.671-9.28 5.015-12.817 6.735-7.074 17.638-7.074 24.324 0l193.9 204.46 193.97-204.46c6.688-7.074 17.639-7.074 24.324 0 6.688 7.075 6.688 18.559 0 25.609l-197.48 208.07c-11.51 12.114-30.117 12.114-41.502 0l-197.53-208.07c-3.344-3.513-5.014-8.19-5.014-12.792z' fill='%234B4A4B'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E ");
      background-size: .813rem;
      background-position: right .5rem center;
      background-repeat: no-repeat;
      box-sizing: border-box;

      &:after {
        display: none;
      }

      select {
        padding: .5rem 1rem;
        line-height: 1.2rem;
        font-size: .875rem;
        width: 100%;
        background: transparent;
        font-weight: 400;
        text-transform: capitalize;
      }
    }

    .btn {
      width: 100%;
      padding: 1rem 0;
    }

    .select {
      height: 3rem;
      position: relative;
      float: left;
      box-sizing: border-box;
      line-height: 3rem;
      width: 100%;
      border-radius: .188rem;
      background-color: #F5F4F3;
    }

    input.btn {
      background-color: #F5F4F3;
      line-height: 2.875rem;
      font-size: .875rem;
      font-weight: 400;
      text-transform: capitalize;
      text-align: left;
      padding: 0 0 0 1rem;
      border-radius: .188rem;

      &::placeholder {
        color: #7F8C8D;
      }
    }
  }

  .autocomplete-suggestion {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #333;
    font-size: .875rem;
    line-height: 1.125rem;
    padding: .75rem 0 .75rem 1rem;
    cursor: pointer;

    .sub-region {
      font-size: .75rem;
    }

    &:hover, &.selected {
      color: $col_primary;

      .sub-region {
        color: $col_primary;
      }
    }
  }
}

@media #{$desktop} {
  .prop,
  .cms,
  .seo,
  .lodge-form {
    .search-container {
      .box {
        .select {
          margin: 0;
        }
      }

      .box input.btn {
        height: 3rem;
        padding: 0 1rem;
      }
    }
  }

  .search-form {
    padding: 1.25rem 0;

    .region-container input,
    .box input,
    .box .select {
      box-shadow: none;
    }

    .box input.btn,
    .box .select {
      border: 1px solid #d8d8d8;
    }
  }
}

// litepicker

.date-box {
  #date {
    margin: 0;
  }

  @media #{$desktop} {
    position: relative;
    max-height: max-content;
  }
}

#static-litepicker-container {
  @media #{$desktop} {
    bottom: unset;
    position: absolute;
    overflow: unset;
  }
}

.litepicker-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  background: $col_white;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 1003;
  opacity: 1;

  @media #{$desktop} {
    top: unset;
    left: unset;
    width: 39rem;
    height: auto;
    height: auto;
    padding: 0 0.625rem;
    box-sizing: border-box;
    border-radius: 0.188rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);

    .container {
      &__months {
        .month-item {
          width: calc(50% - 10px);
          box-sizing: border-box;

          .month-item-weekdays-row {
            padding: 0 0 0.625rem 0;
          }

          .container__days {
            div {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 20px;
              width: 14.285714285% !important;
            }
          }
        }
      }

      &__footer {
        width: 100%;

        .button-apply {
          right: 0.313rem;
        }
      }
    }
  }

  @media #{$desktop-lg} {
    width: 42.75rem;
  }
  @media screen and (min-width: 1440px) {
    width: 50rem;
  }

  .litepicker {
    position: relative !important;
    top: unset !important;
    left: unset !important;
    order: 1;
    height: auto;
    display: block !important;
    margin-top: 2.5rem;

    @media #{$desktop} {
      margin-top: 0;
    }

    .day-item {
      &.is-today {
        color: $col_primary !important;
      }

      &.is-start-date {
        color: $col_white !important;
      }

      &.is-start-date, &.is-end-date {
        background-color: $col_primary !important;

        &:hover {
          color: $col_white !important;
        }
      }

      &.is-in-range {
        background: lighten($col_primary,10%) !important;
      }

      &:hover {
        border-color: $col_primary !important;
        color: $col_primary !important;
        box-shadow: inset 0 0 0 1px $col_primary !important;
      }
    }

    .button-next-month, .button-previous-month {
      cursor: pointer !important;

      &:hover {
        svg {
          fill: $col_primary !important;
        }
      }
    }
  }

  .buttons-container {
    order: 2;
    margin: 0 auto;
    max-width: 30rem;
    width: 100%;
    box-sizing: border-box;
    padding: 0 1rem;
    position: fixed;
    bottom: 1.25rem;
    left: 0;
    z-index: 10000;

    @media #{$desktop} {
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      width: 10rem;
      padding: 0;
    }

    .flex-buttons {
      display: flex;
      flex-direction: row;
      height: 6rem;

      @media #{$desktop} {
        margin-bottom: 0;
        height: auto;
        width: 10rem;
      }

      .flex-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        text-align: center;
        border: 1px solid #dddddd;
        background-color: $col_white;
        outline: none;
        color: #222222;
        position: relative;
        transition-duration: 0.15s;
        transition-timing-function: ease-in-out;
        padding: 8px 16px;
        min-height: 2rem;
        border-radius: 1rem;
        font-size: 0.8rem;
        line-height: 1.25rem;
        margin-right: .5rem;
        height: 2rem;
        white-space: nowrap;

        &.selected {
          border-color: $col_primary;
          -webkit-box-shadow:inset 0 0 0 1px $col_primary;
          -moz-box-shadow:inset 0 0 0 1px $col_primary;
          box-shadow:inset 0 0 0 1px $col_primary;
        }

        @media #{$desktop} {
          padding: 4px 12px;
          &:hover {
            border-color: $col_black;
            &.selected {
              border-color: $col_primary;
            }
          }
        }
      }
    }

    .close-button {
      height: 3rem;
      background: $col_primary;
      font-size: .875rem;
      border-radius: 0.2rem;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
      color: $col_white !important;
      font-weight: 700;
      cursor: pointer;
      bottom: 1.25rem;
      left: 1rem;
      margin-left: auto;
      margin-top: auto;
      width: calc(50% - 1.5rem);
      position: fixed;
      right: 1rem;

      @media #{$desktop}{
        position: inherit;
        width:6rem;

        &:hover {
          background-color: lighten($col_primary, 10%);
        }
      }
    }
  }

  .reset-button {
    color: $col_primary;
    background: $col_white;
    font-size: .875rem;
    border-radius: 0.2rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
    font-weight: 700;
    position: fixed;
    z-index: 10000;
    width: calc(50% - 1.5rem);
    bottom: 1.25rem;
    right: 1rem;
    height: 3rem;

    svg {
      display: none;
    }

    &::before {
      content: "Clear";
    }

    @media #{$desktop} {
      position: absolute;
      padding: 10px 20px;
      width: auto;
      right: 6.5rem;
      height: auto;
      bottom: 1rem;
      z-index: 100000;
      cursor: pointer;

      &:hover {
        color: lighten($col_primary, 3%);
      }
    }
  }

  .container__months {
    box-shadow: none;
  }
}
</style>