<template>
  <section id="error" class="error">
    <div class="container">
      <div class="row">
        <div class="col-1 hidden-sm"></div>
        <div class="col-10">
          <div class="body">
            <div class="box">
              <SearchFormLitepicker
                :loc="$props.loc"
                :wknd="$props.wknd"
                :title="$props.title"
                :params="$props.params"
                :region="$props.region"
              />
            </div>
            <div class="separation">Or</div>
            <div class="links">
              <p class="sub">
                Use one of these handy links:
              </p>
              <ul>
                <li class="link">
                  <a target="_blank" href="/">Home</a>
                </li>
                <li class="link">
                  <a target="_blank" href="/top-deals">Top deals</a>
                </li>
                <li class="link">
                  <a target="_blank" href="/about-us">About Last Minute Cottages</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SearchFormLitepicker from "shared/searchFormLitepicker.vue";

export default {
  props: {
    status: String,
    config: Object,
    wknd: String,
    title: Array,
    region: String,
  },
  methods: {
    checkStatus() {
      return this.status === "404";
    },
  },
  components: {
    SearchFormLitepicker
  },
};
</script>

<style scoped lang="scss">
@import "app/assets/stylesheets/_vars.scss";

.error {
  padding: 1.25rem;
  background-color: $col_xlgrey;
  margin-bottom: -3.25rem;
}

.head {
  display: flex;
  padding-top: 0.5rem;
  flex-direction: row-reverse;
  justify-content: space-around;
  box-sizing: border-box;
}

h2 {
  font-size: 1.5rem;
}

.header {
  padding-left: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    font-weight: bold;
  }
}

.body {
  padding-top: 1.5rem;
  box-sizing: border-box;
}

.ico-facepalm {
  width: 6.5rem;
  height: 5.5rem;
}

.box {
  &::v-deep .search-form {
    background-color: $col_white;
    margin: auto;
    max-width: 40rem;

    .search_loader {
      display: none !important;
    }

    .order-box {
      display: flex;
      margin-bottom: 0;
      flex-direction: column;
    }

    .btn--lrg {
      max-width: unset;
    }

    .search-container {
      box-shadow: none;

      h1 span {
        font-weight: normal;
      }

      .btn {
        font-size: 1rem;
        font-weight: 600;
      }
    }

    h1 {
      margin: 0;
      color: #000;
      text-shadow: none;
      padding: 0;
    }

    h1 span {
      display: block;
      font-size: 1rem;
      margin: 0.5rem 0 1.25rem;
      color: #000;
    }

    input {
      border: 1px solid #d8d8d8;
      box-shadow: none;

      &::placeholder {
        color: $col_black;
        opacity: 1;
      }

      &.search_post {
        border: none;
      }
    }

    .box.error {
      input, .select {
      border: 1px solid $col_red;

        &::placeholder {
          color: $col_red;
          opacity: 1;
        }
      }
    }

    .select {
      box-shadow: none;
      border: 1px solid #d8d8d8;
      margin-bottom: 0;
    }

    .foot {
      label {
        color: $col_black;
        text-shadow: none;

        &::before {
          cursor: pointer;
        }
      }
    }
    
    label {
      &::before {
        left: 0;
      }
    }

    .ctr {
      display: none;
    }
  }
}

.separation {
  text-align: center;
  padding: 1.5rem;
  font-weight: bold;

  &::before {
    display: inline-block;
    margin: 0 0.5rem 0.25rem 0;
    content: "";
    height: 1px;
    width: 40%;
    background-color: $col_black;
  }

  &::after {
    display: inline-block;
    margin: 0 0 0.25rem 0.5rem;
    content: "";
    height: 1px;
    width: 40%;
    background-color: $col_black;
  }
}

.sub {
  font-weight: bold;
  padding-bottom: 0.75rem;
}

.link {
  padding-bottom: 1rem;
  list-style: none;
}

@media #{$xl-phone} {
  .error {
    padding: 2rem 4rem;
  }

  .head {
    justify-content: flex-end;
  }
  
  .header {
    max-width: 18rem;
  }

  .separation {
    padding: 2.5rem;
  }
}

@media #{$desktop} {
  .error {
    padding: 2.5rem 0 4rem 0;
    min-height: calc(100vh - 34.25rem);
  }

  .head {
    h2 {
      font-size: 2.25rem;
    }
  }

  .header {
    max-width: 25rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    p {
      padding-bottom: 1rem;
    }
  }

  .box {
    width: calc(50% - 4.625rem);

    &::v-deep .search-form {
      position: static;
      height: auto;
      padding: 0;

      .search_fc {
        padding-bottom: 0;
      }

      .search-container {
        padding: 1.25rem 1.25rem 1.75rem 1.25rem;
      }

      h1 span {
        margin: .5rem 0;
      }

      .foot {
        padding: 0;
      }
    }
  }

  .ico-facepalm {
    height: 9rem;
    width: 9rem;
  }

  .body {
    display: flex;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
  }

  .sub {
    padding-bottom: 1.25rem;
  }

  .separation {
    align-self: center;
    position: relative;
    margin-top: 3rem;
    padding: 4rem;

    &::before {
      height: 45%;
      width: 1px;
      position: absolute;
      top: 70%;
      left: 50%;
    }

    &::after {
      height: 45%;
      width: 1px;
      position: absolute;
      bottom: 70%;
      right: 50%;
    }
  }

  .links {
    width: 20rem;
  }
}
</style>
